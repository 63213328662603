import {
  CreateSiteLogViewRequest,
  QueryResponse,
  SiteLogViewResponse,
  UpdateSiteLogViewRequest,
} from 'mapistry-shared';
import qs from 'qs';
import http, { apiUrl } from '../httpClient';

export interface FetchViewsParams {
  logId: string;
  organizationId: string;
  projectId: string;
}
export type FetchViewsResponse = SiteLogViewResponse[];

export async function fetchViews({
  logId,
  organizationId,
  projectId,
}: FetchViewsParams): Promise<FetchViewsResponse> {
  const res = await http.get<FetchViewsResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views`,
  );
  return res.data;
}

export interface FetchViewParams {
  logId: string;
  organizationId: string;
  projectId: string;
  viewId: string;
}

export type FetchViewResponse = SiteLogViewResponse;

export async function fetchView({
  logId,
  organizationId,
  projectId,
  viewId,
}: FetchViewParams): Promise<SiteLogViewResponse> {
  const res = await http.get<FetchViewResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}`,
  );
  return res.data;
}

export type CreateViewParams = {
  logId: string;
  organizationId: string;
  projectId: string;
  view: CreateSiteLogViewRequest;
};
export type CreateViewResponse = SiteLogViewResponse;

export async function createView({
  logId,
  organizationId,
  projectId,
  view,
}: CreateViewParams) {
  const res = await http.post<CreateViewResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views`,
    view,
  );
  return res.data;
}

export type UpdateViewParams = {
  logId: string;
  organizationId: string;
  projectId: string;
  view: UpdateSiteLogViewRequest;
  viewId: string;
};
export type UpdateViewResponse = SiteLogViewResponse;

export async function updateView({
  logId,
  organizationId,
  projectId,
  view,
  viewId,
}: UpdateViewParams) {
  const res = await http.put<UpdateViewResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}`,
    view,
  );
  return res.data;
}

export type DeleteViewRequest = {
  logId: string;
  organizationId: string;
  projectId: string;
  viewId: string;
};
export type DeleteViewResponse = string;

export async function deleteView({
  viewId,
  logId,
  organizationId,
  projectId,
}: DeleteViewRequest) {
  const res = await http.delete<DeleteViewResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}`,
  );
  return res.data;
}

export interface FetchViewQueryParams {
  logId: string;
  organizationId: string;
  perPage?: number;
  projectId: string;
  requestedPage?: number;
  viewId: string;
}

export type FetchViewQueryResponse = QueryResponse;

export async function fetchViewQuery({
  logId,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  viewId,
}: FetchViewQueryParams): Promise<FetchViewQueryResponse> {
  const res = await http.get<FetchViewQueryResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}/query?${qs.stringify(
      {
        currentPage: requestedPage ?? 0,
        perPage: perPage ?? 50,
      },
    )}`,
  );
  return res.data;
}

export type DownloadViewRequest = {
  logId: string;
  organizationId: string;
  projectId: string;
  viewId: string;
};

export async function downloadViewResults({
  viewId,
  logId,
  organizationId,
  projectId,
}: DownloadViewRequest): Promise<void> {
  window.open(
    `${apiUrl}/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}/download`,
  );
}

export type FetchViewResultsRequest = DownloadViewRequest & {
  perPage: number;
  requestedPage: number;
};

export async function fetchViewResults({
  viewId,
  logId,
  organizationId,
  projectId,
  perPage,
  requestedPage,
}: FetchViewResultsRequest): Promise<QueryResponse> {
  const res = await http.get<QueryResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}/query?${qs.stringify(
      {
        currentPage: requestedPage ?? 0,
        perPage: perPage ?? 50,
      },
    )}`,
  );
  return res.data;
}
