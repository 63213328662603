import { QueryRequest, QueryResponse } from 'mapistry-shared';
import qs from 'qs';
import http from '../httpClient';

export interface FetchLogQueryParams {
  logId: string;
  organizationId: string;
  perPage?: number;
  projectId: string;
  query: QueryRequest;
  requestedPage?: number;
}

export type FetchLogQueryResponse = QueryResponse;

export async function fetchLogQuery({
  logId,
  organizationId,
  perPage,
  projectId,
  query,
  requestedPage,
}: FetchLogQueryParams): Promise<FetchLogQueryResponse> {
  const res = await http.post<FetchLogQueryResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/query?${qs.stringify(
      {
        currentPage: requestedPage ?? 0,
        perPage: perPage ?? 50,
      },
    )}`,
    query,
  );
  return res.data;
}

export type ValidateQueryParams = {
  logId: string;
  organizationId: string;
  projectId: string;
  query: QueryRequest;
};
export type ValidateQueryResponse = QueryResponse;

export async function validateQuery(validateParams: ValidateQueryParams) {
  return fetchLogQuery({ ...validateParams, requestedPage: 0, perPage: 50 });
}
