import { FormTemplate } from 'mapistry-shared';
import http from '../httpClient';

export type FetchFormTemplateParams = {
  slug: string;
  organizationId: string;
};

export async function fetchFormTemplate({
  slug,
  organizationId,
}: FetchFormTemplateParams) {
  const res = await http.get<FormTemplate>(
    `/api/v2/organizations/${organizationId}/form-templates/${slug}`,
  );
  return res.data;
}
