import {
  CreateFieldRequest,
  CreateResourceTypeRequest,
  EditResourceTypeRequest,
  FieldResponse,
  Pagination,
  ResourceRefResponse,
  ResourceResponse,
  ResourceTypeListResponse,
  ResourceTypeRefResponse,
  SaveResourceRequest,
  SortDirection,
  UpdateFieldRequest,
} from 'mapistry-shared';
import qs from 'qs';
import http from '../httpClient';

export type FetchResourceTypesParams = {
  organizationId: string;
};

export async function fetchResourceTypes({
  organizationId,
}: FetchResourceTypesParams) {
  const res = await http.get<ResourceTypeListResponse[]>(
    `/api/v2/organizations/${organizationId}/resource-types`,
  );
  return res.data;
}

export type FetchResourceTypeParams = {
  organizationId: string;
  resourceTypeId: string;
};

export async function fetchResourceType({
  organizationId,
  resourceTypeId,
}: FetchResourceTypeParams) {
  const res = await http.get<ResourceTypeRefResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}`,
  );
  return res.data;
}

export type CreateResourceTypeParams = {
  organizationId: string;
  resourceType: CreateResourceTypeRequest;
};

export async function createResourceType({
  organizationId,
  resourceType,
}: CreateResourceTypeParams) {
  const res = await http.post<ResourceTypeRefResponse>(
    `/api/v2/organizations/${organizationId}/resource-types`,
    resourceType,
  );
  return res.data;
}

export type UpdateResourceTypeParams = {
  organizationId: string;
  resourceTypeId: string;
  values: EditResourceTypeRequest;
};

export async function updateResourceType({
  organizationId,
  resourceTypeId,
  values,
}: UpdateResourceTypeParams) {
  const res = await http.put<ResourceTypeRefResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}`,
    values,
  );
  return res.data;
}

export type DeleteResourceTypeParams = {
  organizationId: string;
  resourceTypeId: string;
};
type DeletedResourceTypeId = string;
export type DeleteResourceTypeResponse = DeletedResourceTypeId;

export async function deleteResourceType({
  organizationId,
  resourceTypeId,
}: DeleteResourceTypeParams) {
  const res = await http.delete<DeleteResourceTypeResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}`,
  );
  return res.data;
}

export type FetchResourcePropertiesParams = {
  organizationId: string;
  resourceTypeId: string;
};

export async function fetchResourceProperties({
  organizationId,
  resourceTypeId,
}: FetchResourcePropertiesParams) {
  const res = await http.get<FieldResponse[]>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resource-properties`,
  );
  return res.data;
}

export type CreateResourcePropertyParams = {
  organizationId: string;
  resourceTypeId: string;
  property: CreateFieldRequest;
};

export async function createResourceProperty({
  organizationId,
  resourceTypeId,
  property,
}: CreateResourcePropertyParams) {
  const res = await http.post<FieldResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resource-properties`,
    property,
  );
  return res.data;
}

export type UpdateResourcePropertyParams = {
  organizationId: string;
  resourceTypeId: string;
  resourcePropertyId: string;
  values: UpdateFieldRequest;
};

export async function updateResourceProperty({
  organizationId,
  resourceTypeId,
  resourcePropertyId,
  values,
}: UpdateResourcePropertyParams) {
  const res = await http.put<FieldResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resource-properties/${resourcePropertyId}`,
    values,
  );
  return res.data;
}

export type DeleteResourcePropertyParams = {
  organizationId: string;
  resourceTypeId: string;
  resourcePropertyId: string;
};
export type DeleteResourcePropertyResponse = string;

export async function deleteResourceProperty({
  organizationId,
  resourceTypeId,
  resourcePropertyId,
}: DeleteResourcePropertyParams) {
  const res = await http.delete<DeleteResourcePropertyResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resource-properties/${resourcePropertyId}`,
  );
  return res.data;
}

export type FetchResourcesParams = {
  nameFilter?: string;
  organizationId: string;
  perPage?: number;
  projectId?: string;
  requestedPage?: number;
  resourceTypeId: string;
  sortDirection?: SortDirection;
};

export type FetchResourcesResponse = {
  data: ResourceResponse[];
  pagination: Pagination;
};

export async function fetchResources({
  nameFilter,
  organizationId,
  perPage,
  projectId,
  requestedPage,
  resourceTypeId,
  sortDirection,
}: FetchResourcesParams) {
  const body = {
    currentPage: requestedPage ?? 0,
    perPage: perPage ?? 50,
    sortDirection: sortDirection || SortDirection.ASC,
    ...(projectId ? { projectId } : {}),
    ...(nameFilter ? { searchFilter: nameFilter } : {}),
  };
  const res = await http.post<FetchResourcesResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/paginated`,
    body,
  );
  return res.data;
}

export type FetchResourceRefsParams = {
  organizationId: string;
  projectId?: string;
  resourceTypeId: string;
};

export type FetchResourceRefsResponse = ResourceRefResponse[];

export async function fetchResourceRefs({
  organizationId,
  projectId,
  resourceTypeId,
}: FetchResourceRefsParams) {
  const res = await http.get<FetchResourceRefsResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/refs${
      projectId ? `?${qs.stringify({ projectId })}` : ''
    }`,
  );
  return res.data;
}

export type FetchResourceParams = {
  organizationId: string;
  resourceTypeId: string;
  resourceId: string;
};

export type FetchResourceResponse = ResourceResponse;

export async function fetchResource({
  organizationId,
  resourceTypeId,
  resourceId,
}: FetchResourceParams) {
  const res = await http.get<FetchResourceResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/${resourceId}`,
  );
  return res.data;
}

type CreateResourceParams = {
  organizationId: string;
  resourceTypeId: string;
  resource: SaveResourceRequest;
};

export async function createResource({
  organizationId,
  resourceTypeId,
  resource,
}: CreateResourceParams): Promise<ResourceResponse> {
  const res = await http.post<ResourceResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources`,
    resource,
  );
  return res.data;
}

type UploadResourcesParams = {
  organizationId: string;
  resourceTypeId: string;
  resources: SaveResourceRequest[];
};

export async function uploadResources({
  organizationId,
  resourceTypeId,
  resources,
}: UploadResourcesParams): Promise<void> {
  await http.post<ResourceResponse[]>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/bulk`,
    resources,
  );
}

type UpdateResourceParams = {
  organizationId: string;
  resourceTypeId: string;
  resourceId: string;
  resource: SaveResourceRequest;
};

export async function updateResource({
  organizationId,
  resourceTypeId,
  resourceId,
  resource,
}: UpdateResourceParams): Promise<ResourceResponse> {
  const res = await http.put<ResourceResponse>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/${resourceId}`,
    resource,
  );
  return res.data;
}

type DeleteResourceParams = {
  organizationId: string;
  resourceTypeId: string;
  resourceId: string;
};

export async function deleteResource({
  organizationId,
  resourceTypeId,
  resourceId,
}: DeleteResourceParams) {
  await http.delete<void>(
    `/api/v2/organizations/${organizationId}/resource-types/${resourceTypeId}/resources/${resourceId}`,
  );
}
