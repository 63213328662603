import LeftArrowIcon from '@svg/m-arrow-left.svg';
import RightArrowIcon from '@svg/m-arrow-right.svg';
import _isEqual from 'lodash.isequal';
import {
  CustomFrequencyMessage,
  FullMonths,
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  AnimatedComponent,
  Button,
  Card,
  FiltersLine,
  IconButton,
  LoadingIndicator,
} from '../../../elements';
import { ANIMATION_TYPES } from '../../../elements/AnimatedComponent';
import { CustomFrequency, MenuOptionType } from '../../../propTypes';
import CalendarCardSkeleton from './CalendarCardSkeleton';
import DatePickerOverlay from './DatePickerOverlay';
import HighFrequencyEventCalendar from './HighFrequencyEventCalendar';
import LowFrequencyEventCalendar from './LowFrequencyEventCalendar';

const MonthNames = Object.values(FullMonths).map((month) =>
  month.toUpperCase(),
);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default class CalendarCard extends Component {
  constructor(props) {
    super(props);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleDatePickerCancel = this.handleDatePickerCancel.bind(this);
    this.handleDatePickerConfirm = this.handleDatePickerConfirm.bind(this);
    this.state = {
      showDatePickerOverlay: false,
    };
  }

  handleDateClick() {
    this.setState({ showDatePickerOverlay: true });
  }

  handleDatePickerCancel() {
    this.setState({ showDatePickerOverlay: false });
  }

  handleDatePickerConfirm(selectedDate) {
    const { onDateChange } = this.props;
    this.setState({ showDatePickerOverlay: false });
    onDateChange(selectedDate);
  }

  lowFrequencyHeaderDate() {
    const { currentDate, eventGroups } = this.props;
    const eventGroupKeys = Object.keys(eventGroups);
    if (!eventGroupKeys.length) {
      return currentDate.getFullYear();
    }
    const firstEventGroup = eventGroups[eventGroupKeys[0]];
    const lastEventGroup =
      eventGroups[eventGroupKeys[eventGroupKeys.length - 1]];
    const firstYear = firstEventGroup[0].dueDate
      ? firstEventGroup[0].dueDate.getFullYear()
      : firstEventGroup[0].startDate.getFullYear();
    const lastYear = lastEventGroup[0].dueDate
      ? lastEventGroup[0].dueDate.getFullYear()
      : lastEventGroup[0].startDate.getFullYear();
    if (firstYear !== lastYear) {
      return `${firstYear} - ${lastYear}`;
    }
    return firstYear;
  }

  formatDateHeader() {
    const { currentDate, frequency, customFrequency } = this.props;

    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );

    if (
      frequency === IntervalFrequencyEnum.NEVER ||
      frequency === IntervalFrequencyEnum.HOUR ||
      !frequency
    ) {
      return `${
        MonthNames[currentDate.getMonth()]
      } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    }
    if (intervalFrequency.isLow()) {
      return this.lowFrequencyHeaderDate();
    }
    return `${MonthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
  }

  isSingleFrequency() {
    const { eventGroups, frequency, customFrequency } = this.props;
    return Object.keys(eventGroups).every((key) =>
      eventGroups[key].every(
        (event) =>
          event.frequency === frequency &&
          _isEqual(event.customFrequency, customFrequency),
      ),
    );
  }

  title() {
    const { frequency, customFrequency } = this.props;
    const isSingleFrequency = this.isSingleFrequency();

    if (!isSingleFrequency) {
      return (
        <div className="calendar-card__frequency-title">Mixed Frequencies</div>
      );
    }

    const msg = new CustomFrequencyMessage(frequency, customFrequency);

    return (
      <>
        <div className="calendar-card__frequency-title">
          {IntervalFrequencyFactory.For(frequency, customFrequency).toString()}
        </div>
        <div className="calendar-card__frequency-sub-title">
          {msg.getShortMessage()}
        </div>
      </>
    );
  }

  navigation() {
    const { onPrevDateClick, onNextDateClick } = this.props;

    return (
      <div className="calendar-card__nav">
        <IconButton onClick={onPrevDateClick}>
          <LeftArrowIcon
            aria-hidden
            className="m-icon calendar-card__nav-icon grow-large"
          />
          <div className="visually-hidden">Back</div>
        </IconButton>
        <Button variant="text" onClick={this.handleDateClick}>
          {this.formatDateHeader()}
        </Button>
        <IconButton onClick={onNextDateClick}>
          <RightArrowIcon
            aria-hidden
            className="m-icon calendar-card__nav-icon grow-large"
          />
          <div className="visually-hidden">Next</div>
        </IconButton>
      </div>
    );
  }

  calendar() {
    const {
      currentDate,
      eventGroups,
      frequency,
      customFrequency,
      helpTextForEvent,
      tooltipData,
      tooltipTitle,
      isEventClickable,
      defaultView,
      onEventClick,
    } = this.props;
    if (!frequency) {
      return <div>Loading...</div>;
    }
    if (frequency === IntervalFrequencyEnum.HOUR) {
      return <div>Hourly - need to complete</div>;
    }
    const intervalFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    if (intervalFrequency.isLow()) {
      return (
        <LowFrequencyEventCalendar
          eventGroups={eventGroups}
          helpTextForEvent={helpTextForEvent}
          tooltipData={tooltipData}
          tooltipTitle={tooltipTitle}
          isEventClickable={isEventClickable}
          onEventClick={onEventClick}
        />
      );
    }
    if (intervalFrequency.isHigh() || !intervalFrequency.hasDueDate()) {
      return (
        <HighFrequencyEventCalendar
          currentDate={currentDate}
          eventGroups={eventGroups}
          tooltipData={tooltipData}
          tooltipTitle={tooltipTitle}
          isEventClickable={isEventClickable}
          onEventClick={onEventClick}
        />
      );
    }
    return defaultView;
  }

  render() {
    const {
      currentDate,
      filterView,
      frequencySubtext,
      isInitializing,
      isLoading,
      menuOptions,
      selectedFilterOptions,
      title,
    } = this.props;
    const { showDatePickerOverlay } = this.state;
    return showDatePickerOverlay ? (
      <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
        <DatePickerOverlay
          currentDate={currentDate}
          onCancel={this.handleDatePickerCancel}
          onConfirm={this.handleDatePickerConfirm}
        />
      </AnimatedComponent>
    ) : (
      <AnimatedComponent type={ANIMATION_TYPES.FADE_IN_OUT}>
        {isInitializing ? (
          <CalendarCardSkeleton />
        ) : (
          <Card
            title={title}
            actions={filterView}
            menuOptions={menuOptions}
            ref={(calendarCardContainer) => {
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line react/no-unused-class-component-methods
              this.calendarCardContainerRef = calendarCardContainer;
            }}
          >
            <div
              className="calendar-card"
              ref={(calendarCard) => {
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line react/no-unused-class-component-methods
                this.calendarCardRef = calendarCard;
              }}
            >
              {isLoading && <LoadingIndicator />}
              <FiltersLine filterOptions={selectedFilterOptions} />
              {this.title()}
              <div className="calendar-card__frequency-subtext">
                {frequencySubtext}
              </div>
              {this.navigation()}
              {this.calendar()}
            </div>
          </Card>
        )}
      </AnimatedComponent>
    );
  }
}

CalendarCard.propTypes = {
  currentDate: PropTypes.instanceOf(Date).isRequired,
  defaultView: PropTypes.node,
  eventGroups: PropTypes.shape({}).isRequired,
  filterView: PropTypes.node,
  frequency: PropTypes.string,
  customFrequency: CustomFrequency,
  frequencySubtext: PropTypes.string,
  helpTextForEvent: PropTypes.func.isRequired,
  tooltipData: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  tooltipTitle: PropTypes.func.isRequired,
  isEventClickable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInitializing: PropTypes.bool.isRequired,
  menuOptions: PropTypes.arrayOf(MenuOptionType),
  onDateChange: PropTypes.func.isRequired,
  onEventClick: PropTypes.func,
  onPrevDateClick: PropTypes.func.isRequired,
  onNextDateClick: PropTypes.func.isRequired,
  selectedFilterOptions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

CalendarCard.defaultProps = {
  defaultView: null,
  filterView: null,
  frequency: null,
  customFrequency: null,
  frequencySubtext: '',
  isEventClickable: false,
  isLoading: false,
  menuOptions: [],
  onEventClick: null,
  selectedFilterOptions: [],
  tooltipData: null,
};
