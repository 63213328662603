import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Main } from './Main';
import { VisuallyHidden } from './VisuallyHidden';

export interface LoadingProps {
  size?: 'small' | 'medium' | 'large';
}

const sizes = {
  small: 20,
  medium: 40,
  large: 80,
};

const FullScreen = styled(Main)`
  width: 100vw;
  height: 100vh;
`;

const FullWidthAndCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto;
`;

const GreenCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.darkGreen};
`;

export const Loading = (props: LoadingProps) => {
  const { size = 'large' } = props;

  return (
    <FullWidthAndCentered>
      <GreenCircularProgress aria-hidden size={sizes[size]} />
      <VisuallyHidden>Loading...</VisuallyHidden>
    </FullWidthAndCentered>
  );
};

export const FullScreenLoadingPage = () => (
  <FullScreen>
    <Loading />
  </FullScreen>
);
