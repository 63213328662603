/* eslint-disable */
// when editing this file please uncomment the above and fix the linting errors
import update from 'immutability-helper';
import _get from 'lodash.get';
import { handleActions } from 'redux-actions';
import _ from 'underscore';

import {
  CLEAR_CALENDAR_FREQUENCIES,
  CREATE_CALENDAR_ASSIGNEES_FAILURE,
  CREATE_CALENDAR_ASSIGNEES_REQUEST,
  CREATE_CALENDAR_ASSIGNEES_SUCCESS,
  CREATE_CALENDAR_FREQUENCY_FAILURE,
  CREATE_CALENDAR_FREQUENCY_REQUEST,
  CREATE_CALENDAR_FREQUENCY_SUCCESS,
  DELETE_CALENDAR_FREQUENCY_FAILURE,
  DELETE_CALENDAR_FREQUENCY_REQUEST,
  DELETE_CALENDAR_FREQUENCY_SUCCESS,
  DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE,
  DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST,
  DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS,
  FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE,
  FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST,
  FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_FREQUENCIES_FAILURE,
  FETCH_CALENDAR_FREQUENCIES_REQUEST,
  FETCH_CALENDAR_FREQUENCIES_SUCCESS,
  FETCH_CALENDAR_MICRO_INTERVALS_FAILURE,
  FETCH_CALENDAR_MICRO_INTERVALS_REQUEST,
  FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS,
  FETCH_CALENDAR_PARENT_INTERVALS_FAILURE,
  FETCH_CALENDAR_PARENT_INTERVALS_REQUEST,
  FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS,
  FETCH_CALENDAR_SUMMARY_FAILURE,
  FETCH_CALENDAR_SUMMARY_REQUEST,
  FETCH_CALENDAR_SUMMARY_SUCCESS,
  FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE,
  FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST,
  FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS,
  FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE,
  FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST,
  FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS,
  REMOVE_CALENDAR_ASSIGNEES_FAILURE,
  REMOVE_CALENDAR_ASSIGNEES_REQUEST,
  REMOVE_CALENDAR_ASSIGNEES_SUCCESS,
  RESET_CALENDAR_ERROR_STATE,
} from '../actions';

import { failed, fetching, notFetching } from '.'; // eslint-disable-line import/no-cycle

const reducers = handleActions(
  {
    [FETCH_SAMPLING_CALENDAR_EVENTS_REQUEST]: (state) => fetching(state),
    [FETCH_SAMPLING_CALENDAR_EVENTS_SUCCESS]: (state, action) => {
      const { parameterSlug } = action;
      const { calendarName } = action.payload;

      // If initial events load for this calendar
      if (!state.samplingEvents[calendarName]) {
        return notFetching(
          update(state, {
            samplingEvents: {
              [calendarName]: {
                $set: {
                  [parameterSlug]: action.payload.events,
                },
              },
            },
          }),
        );
      }
      // If initial events load for this parameter
      if (!state.samplingEvents[calendarName][parameterSlug]) {
        return notFetching(
          update(state, {
            samplingEvents: {
              [calendarName]: {
                [parameterSlug]: {
                  $set: action.payload.events,
                },
              },
            },
          }),
        );
      }

      // If we already loaded events for specific calendar and parameter
      // we need to update a list of events with what we've got from the server
      const nextEvents = state.samplingEvents;
      _.map(state.samplingEvents[calendarName][parameterSlug], (v, key) => {
        const existingEventIdx = nextEvents[calendarName][parameterSlug][
          key
        ].events.findIndex(
          (e) =>
            e.startDate ===
            _get(action.payload.events, `${[key]}.events[0].startDate`),
        );
        if (existingEventIdx > -1) {
          return (nextEvents[calendarName][parameterSlug][key].events = update(
            nextEvents[calendarName][parameterSlug][key].events,
            {
              [existingEventIdx]: {
                $set: action.payload.events[key].events[0],
              },
            },
          ));
        }
        return (nextEvents[calendarName][parameterSlug][key].events = update(
          nextEvents[calendarName][parameterSlug][key].events,
          {
            $push: action.payload.events[key].events,
          },
        ));
      });

      return notFetching(
        update(state, {
          samplingEvents: {
            $merge: nextEvents,
          },
        }),
      );
    },
    [FETCH_SAMPLING_CALENDAR_EVENTS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),

    [FETCH_CALENDAR_EVENTS_REQUEST]: (state) => fetching(state),
    [FETCH_CALENDAR_EVENTS_SUCCESS]: (state, action) => {
      const { calendarName, widgetId } = action.payload;
      return widgetId
        ? notFetching(
            update(state, {
              events: {
                [widgetId]: {
                  $set: action.payload.events,
                },
              },
            }),
          )
        : notFetching(
            update(state, {
              events: {
                [calendarName]: {
                  $set: action.payload.events,
                },
              },
            }),
          );
    },
    [FETCH_CALENDAR_EVENTS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),

    [FETCH_CALENDAR_ATTACHMENT_COUNT_REQUEST]: (state) =>
      fetching(state, 'calendarAttachmentCount'),
    [FETCH_CALENDAR_ATTACHMENT_COUNT_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          attachmentCounts: {
            [action.payload.calendarId]: {
              $set: action.payload.attachmentCount,
            },
          },
        }),
        'calendarAttachmentCount',
      ),
    [FETCH_CALENDAR_ATTACHMENT_COUNT_FAILURE]: (state, action) =>
      failed(notFetching(state, 'calendarAttachmentCount'), action.payload),
    [FETCH_CALENDAR_SUMMARY_REQUEST]: (state) =>
      fetching(state, 'calendarSummary'),
    [FETCH_CALENDAR_SUMMARY_SUCCESS]: (state, action) =>
      notFetching(
        update(state, { summary: { $set: action.payload.summary } }),
        'calendarSummary',
      ),
    [FETCH_CALENDAR_SUMMARY_FAILURE]: (state, action) =>
      failed(notFetching({ state }, 'calendarSummary'), action.payload),
    [DOWNLOAD_CALENDAR_ATTACHMENTS_REQUEST]: (state) =>
      fetching(state, 'downloadCalendarAttachments'),
    [DOWNLOAD_CALENDAR_ATTACHMENTS_SUCCESS]: (state) =>
      notFetching(state, 'downloadCalendarAttachments'),
    [DOWNLOAD_CALENDAR_ATTACHMENTS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'downloadCalendarAttachments'), action.payload),

    [FETCH_INSPECTION_CALENDAR_STENCILS_REQUEST]: (state) =>
      fetching(state, 'inspectionCalendarStencils'),
    [FETCH_INSPECTION_CALENDAR_STENCILS_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          formStencils: {
            [action.payload.calendarName]: {
              $set: action.payload.formStencils,
            },
          },
        }),
        'inspectionCalendarStencils',
      ),
    [FETCH_INSPECTION_CALENDAR_STENCILS_FAILURE]: (state, action) =>
      failed(notFetching(state, 'inspectionCalendarStencils'), action.payload),

    [CLEAR_CALENDAR_FREQUENCIES]: (state, action) =>
      update(state, {
        frequencies: {
          [action.payload.calendarName]: {
            $set: {},
          },
        },
      }),

    [CREATE_CALENDAR_FREQUENCY_REQUEST]: (state) =>
      fetching(state, 'calendarFrequencies'),
    [CREATE_CALENDAR_FREQUENCY_SUCCESS]: (state, action) => {
      const { calendarName, frequency } = action.payload;
      return notFetching(
        update(state, {
          frequencies: {
            [calendarName]: { $push: [frequency] },
          },
          errorMessage: { $set: null },
        }),
        'calendarFrequencies',
      );
    },
    [CREATE_CALENDAR_FREQUENCY_FAILURE]: (state, action) =>
      failed(notFetching(state, 'calendarFrequencies'), action.payload),

    [DELETE_CALENDAR_FREQUENCY_REQUEST]: (state) =>
      fetching(state, 'calendarFrequencies'),
    [DELETE_CALENDAR_FREQUENCY_SUCCESS]: (state, action) => {
      const { calendarName, id } = action.payload;
      const deletedRecordIdx = state.frequencies[calendarName].findIndex(
        (frequency) => frequency.id === id,
      );
      return notFetching(
        update(state, {
          frequencies: {
            [calendarName]: { $splice: [[deletedRecordIdx, 1]] },
          },
          errorMessage: { $set: null },
        }),
        'calendarFrequencies',
      );
    },
    [DELETE_CALENDAR_FREQUENCY_FAILURE]: (state, action) =>
      failed(notFetching(state, 'calendarFrequencies'), action.payload),

    [RESET_CALENDAR_ERROR_STATE]: (state) =>
      update(state, { errorMessage: { $set: null } }),

    [FETCH_CALENDAR_FREQUENCIES_REQUEST]: (state) =>
      fetching(state, 'calendarFrequencies'),
    [FETCH_CALENDAR_FREQUENCIES_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          frequencies: {
            [action.payload.calendarName]: {
              $set: action.payload.frequencies,
            },
          },
        }),
        'calendarFrequencies',
      ),
    [FETCH_CALENDAR_FREQUENCIES_FAILURE]: (state, action) =>
      failed(notFetching(state, 'calendarFrequencies'), action.payload),

    [FETCH_CALENDAR_PARENT_INTERVALS_REQUEST]: (state) => fetching(state),
    [FETCH_CALENDAR_PARENT_INTERVALS_SUCCESS]: (state, action) =>
      notFetching(
        update(state, {
          parentIntervals: {
            [action.payload.calendarName]: {
              $set: _.omit(action.payload, 'calendarName'),
            },
          },
        }),
      ),
    [FETCH_CALENDAR_PARENT_INTERVALS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),
    [FETCH_CALENDAR_MICRO_INTERVALS_REQUEST]: (state) => fetching(state),
    [FETCH_CALENDAR_MICRO_INTERVALS_SUCCESS]: (state, action) => {
      // checking for nonCustomFrequency here first because some frequencies (e.g.
      // Wastewater Sampling Parameters) get created from deprecated IntervalFrequencies
      const frequency = _get(
        action,
        'payload[0].customFrequency.nonCustomFrequency',
        _get(action, 'payload[0].frequency', null),
      );
      const nextMicroIntervals = state.microIntervals;
      if (!nextMicroIntervals[frequency]) {
        return notFetching(
          update(state, {
            microIntervals: {
              [frequency]: {
                $set: action.payload,
              },
            },
          }),
        );
      }
      return notFetching(
        update(state, {
          microIntervals: {
            [frequency]: {
              $push: action.payload,
            },
          },
        }),
      );
    },
    [FETCH_CALENDAR_MICRO_INTERVALS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),
    [FETCH_CALENDAR_MICRO_INTERVALS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),
    [CREATE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'createAssignees'),
    [CREATE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'createAssignees',
      ),
    [CREATE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(
        // TODO I suspect that payload is populated with only the first arg with is body, not error
        notFetching(state, 'createAssignees'),
        action.payload,
      ),
    [REMOVE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'removeAssignees'),
    [REMOVE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'removeAssignees',
      ),
    [REMOVE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(
        // TODO I suspect that payload is populated with only the first arg with is body, not error
        notFetching(state, 'removeAssignees'),
        action.payload,
      ),
    [FETCH_CALENDAR_MICRO_INTERVALS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),
    [CREATE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'createAssignees'),
    [CREATE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'createAssignees',
      ),
    [CREATE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(notFetching(state, 'createAssignees'), action.payload),
    [REMOVE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'removeAssignees'),
    [REMOVE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'removeAssignees',
      ),
    [REMOVE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(notFetching(state, 'removeAssignees'), action.payload),
    [FETCH_CALENDAR_MICRO_INTERVALS_FAILURE]: (state, action) =>
      failed(notFetching(state), action.payload),
    [CREATE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'createAssignees'),
    [CREATE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'createAssignees',
      ),
    [CREATE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(notFetching(state, 'createAssignees'), action.payload),
    [REMOVE_CALENDAR_ASSIGNEES_REQUEST]: (state) =>
      fetching(state, 'removeAssignees'),
    [REMOVE_CALENDAR_ASSIGNEES_SUCCESS]: (state) =>
      notFetching(
        update(state, { errorMessage: { $set: null } }),
        'removeAssignees',
      ),
    [REMOVE_CALENDAR_ASSIGNEES_FAILURE]: (state, action) =>
      failed(notFetching(state, 'removeAssignees'), action.payload),
  },
  {
    attachmentCounts: {},
    events: {},
    formStencils: {},
    frequencies: {},
    isFetching: {},
    microIntervals: {},
    parentIntervals: {},
    samplingEvents: {},
    summary: null,
  },
);

export default reducers;
